<template>
  <div>
    <!-- Show the create form -->
    <v-dialog
      :value="value"
      @input="$emit('input', $event)"
      max-width="600"
      :persistent="isMakingRequest"
    >
      <v-card :loading="isMakingRequest">
        <v-card-title class="d-flex justify-space-between primary white--text pb-4">
          <div class="d-flex align-center">
            Content Discovery Search
          </div>
        </v-card-title>

        <v-card-text class="pt-6">
          <div
            v-if="influencer"
            class="d-flex align-center"
          >
            <v-img
              height="30"
              width="30"
              max-width="30"
              class="mr-3"
              :src="platformIconMap[influencer.platform]"
            />

            <profile-chip
              :data="influencer.preview || influencer"
              :platform="influencer.platform"
            />
          </div>

          <div
            v-else
            class="d-flex"
          >
            <!-- show the input for searching terms -->
            <profile-selector
              @change="handleProfileChange"
              :platform="selectedPlatform"
              :use-combobox="true"
              :hide-no-data="true"
              type="search"
              label="Search Profile"
              class="rounded-tr-0 rounded-br-0"
              outlined
            />

            <!-- the select options for different platforms -->
            <platform-selector
              :value="selectedPlatform"
              @input="handlePlatformChange"
              :show-youtube="false"
              class="rounded-tl-0 rounded-bl-0"
              outlined
            />
          </div>

          <!-- Show the title input -->
          <v-text-field
            v-model="formData.title"
            label="Report Title"
            placeholder="Optional"
            @input="$v.formData.title.$touch()"
            @blur="$v.formData.title.$touch()"
            :hide-details="!$v.formData.title.$anyError"
            :error-messages="$v.formData.title.$anyError ? ['Please enter a title under 100 characters'] : null"
            class="my-6"
            outlined
          ></v-text-field>

          <!-- Show the keyword input -->
          <v-text-field
            v-model="formData.keyword"
            label="Search Keyword"
            @input="$v.formData.keyword.$touch()"
            @blur="$v.formData.keyword.$touch()"
            :hide-details="!$v.formData.keyword.$anyError"
            :error-messages="$v.formData.keyword.$anyError ? ['Please enter a search keyword under 100 characters'] : null"
            class="my-6"
            outlined
          ></v-text-field>

          <!-- Show a brief message about module -->
          <p>
            Enter a keyword to find the influencer's posts using our intelligent search!
          </p>
        </v-card-text>

        <v-card-actions>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-chip
                label
                color="primary"
                v-on="on"
              >
                <v-icon
                  left
                  small
                >
                  account_balance
                </v-icon>

                {{ nFormatter(availableModuleUsage) }} Reports
              </v-chip>
            </template>

            <span>
              You have {{ availableModuleUsage }} reports available to generate
            </span>
          </v-tooltip>

          <v-spacer />

          <v-btn
            text
            color="primary"
            :disabled="isMakingRequest"
            @click="$emit('input', false)"
          >
            Cancel
          </v-btn>

          <v-btn
            depressed
            color="primary"
            :loading="isMakingRequest"
            :disabled="isMakingRequest || availableModuleUsage <= 0"
            @click="handleSubmit"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import helper functions
import { required, maxLength } from "vuelidate/lib/validators"

// Import children components
const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */ '@/blocks/common/ProfileChip')
const ProfileSelector = () => import(/* webpackChunkName: "profile-selector" */ "@/blocks/common/selectors/ProfileSelector.vue")
const PlatformSelector = () => import(/* webpackChunkName: "platform-selector" */ "@/blocks/common/selectors/PlatformSelector.vue")

// Define function for initial form data
const initialFormData = () => ({
  title: "",
  keyword: ""
})

// Export the SFC
export default {
  // Name of the component
  name: "ContentDiscoveryForm",

  // Register the components
  components: {
    ProfileChip,
    ProfileSelector,
    PlatformSelector
  },

  // Define the props
  props: {
    value: {
      type: Boolean,
      default: false
    },

    source: {
      type: String,
      default: "self"
    },

    influencer: {
      type: Object,
      required: false,
      default: null
    },

    input: {
      type: String,
      required: false,
      default: null
    }
  },

  // Define local data variables
  data: () => ({
    isMakingRequest: false,

    formData: initialFormData(),

    selectedPlatform: "instagram",
    selectedInfluencer: null,
  }),

  // Define local computable properties
  computed: {
    /**
     * Compute the available module usage
     *
     * @returns {Number}
     */
    availableModuleUsage() {
      return this.$store.getters["auth/availableModuleUsage"]("content-discovery")
    },
  },

  // Define the validations
  validations: {
    formData: {
      title: {
        maxLength: maxLength(100)
      },

      keyword: {
        required,
        maxLength: maxLength(100)
      }
    }
  },

  // Define the watchers
  watch: {
    // Watch the input prop
    input: {
      deep: true,
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          // Update the text input
          this.formData.keyword = newValue
        }
      }
    }
  },

  // Define local method functions
  methods: {
    // Handle the profile change
    handleProfileChange(profile) {
      this.selectedInfluencer = profile
    },

    // Handle the platform change
    handlePlatformChange(platform) {
      this.selectedPlatform = platform
    },

    /**
     * Handle the form submission
     *
     * @return {void}
     */
    async handleSubmit() {
      // If we're already making a request
      if (this.isMakingRequest) {
        return
      }

      // Validate the form
      await this.$v.$touch()

      // Check if there's no influencer
      if (!this.selectedInfluencer && !this.influencer) {
        // Show an error toast
        this.$store.dispatch("toasts/add", { text: "Please select an influencer" })

        // Return early
        return
      }

      // Check if the form is invalid
      if (this.$v.$invalid) {
        // Show an error toast
        this.$store.dispatch("toasts/add", { text: "Please fix the errors in the form" })

        // Return early
        return
      }

      // Otherwise, set a global loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingRequest = true

      // Make the network request
      try {
        // Get the response
        await axios({
          url: "/api/content-discovery-searches",
          method: "POST",
          data: {
            title: this.formData.title,
            keyword: this.formData.keyword,

            platform: this.influencer ? this.influencer.platform : this.selectedPlatform,
            username: this.influencer ? this.influencer.username : this.selectedInfluencer.username || this.selectedInfluencer,

            source: this.source,
            sharing_access: "self",

            sort: this.sort,
            filters: this.filters,
            audience_source: this.audienceSource,
          }
        })

        // Refresh the user's balance
        fetchProfile()

        // Show a success toast
        this.$store.dispatch("toasts/add", { text: "Report queued! We'll notify you once it's ready." })

        // Emit the close event
        this.$emit("input", false)
        this.$emit("created")

        // Reset the form data
        this.formData = initialFormData()
        this.$v.$reset()
      } catch (error) {
        // Log the error
        logger({ type: "ContentDiscovery/Form/Create Error", error })

        // Show an error toast
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred, please try again!" })
      } finally {
        // Hide the loader
        this.$store.dispatch("loaders/remove", loaderId)
        this.isMakingRequest = false
      }
    }
  }
}
</script>
